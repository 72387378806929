<template>
    <div class="phone_page">
        <img style="background: var(--img-bg)" :src="require('@/assets/imgs/about_imgs/page1_bg2.png')" width="100%" alt="">
        <div class="phone_title1">
            <p>关于我们</p>
            <span>新疆好物传颂者</span>
        </div>
        <div class="page_container">
            <div class="page_title">
                <div class="page_title_en">COMPANY INTRODUCTION</div>
                <div class="page_title_cn">企业简介</div>
            </div>
            <div class="section1">
                <div class="text_container">
                    <div class="text_item">
                        <div class="point"></div>
                        <div class="text_item_box">新疆好物疆至企业管理有限公司成立于2021年，注册资本2000万人民币。</div>
                    </div>
                    <div class="text_item1">
                        <div class="point"></div>
                        <div class="text_item_box">好物疆至以“新疆好物传颂者”为企业定位，以“重塑本地好物价值体系，促进本地特色文化传递，领航本地产业结构升级，带动本地人民共同富裕”为企业使命，以“丰富社会主义市场经济体制，共享社会主义经济发展红利”为企业愿景。</div>
                    </div>
                    <div class="text_item2">
                        <div class="point"></div>
                        <div class="text_item_box">通过旗下旅游开发、文化传媒、贸易销售、食品加工、酒店运营、文创研发等相关子公司，响应国家优化发展实体经济的政策，以幸福美好生活为导向，充分利用大美新疆的品质在地资源和历史文化传承，为广大消费者提供畅游新疆美景、感受新疆风情、持续消费新疆好物的多元复合场景综合服务。</div>
                    </div>
                </div>
                <div class="img_container">
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/2about/img/%E4%BC%81%E4%B8%9A%E7%AE%80%E4%BB%8B.jpg" />
                </div>
            </div>
        </div>
        <div class="section2">
            <div class="text_container">
                <div class="text_item1">
                    <div class="point"></div>
                    <div class="text_item_box">好物疆至以旅游为切入点，打通一二三产业链，实现高质量发展。好物疆至将通过旅游产业园（野奢酒店、可视化互动体验产销区等）完成流量沉淀和认知输出，通过新消费矩阵（疆内特产文创店、疆外零食瓜果铺、疆外新疆生活馆等）完成产品升级和产品输出，通过终端售卖数据精准判断市场趋势，与本地人民进行可视化种养殖、可视化加工厂的全面合作，实现更合理的产能分配，达到优化产业结构的目标，最终打通一二三产业，创造价值增量，实现整体化高质量发展。</div>
                </div>
                <div class="text_item2">
                    <div class="point"></div>
                    <div class="text_item_box">好物疆至还将以APP为媒介，实现通过以旅游为切入点建立的全产业链体系的利润共享。在好物疆至APP上，短视频等各类型的线上娱乐只是基础功能，更核心的是我们会将产业链上每个环节的经营单体（酒店、连锁零售门店、种养殖基地、加工厂等）进行运营、财务的全透明线上化，用户可在APP上实现对项目的全透明化投资操作、日常运营监督和收益获取。每个单体项目我们都将设置较低的投资参与额度，以此让更广大的人民群众都可以参与到新疆乃至整个中国的发展红利中来，实现真正的共同富裕。</div>
                </div>
            </div>
            <div class="img_container">
                <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/2about/img/5.png" />
            </div>
        </div>
        <footer1></footer1>
    </div>
</template>

<script>
export default {
    mounted() {
        this.animate.moveIn(".phone_title1", ".phone_title1", "bottom");
    }
}
</script>

<style lang="scss" scoped>
.phone_page{
    display: block;
    position:relative;
    margin-top: 75px;

    img{
        height: 344px;
    }
    .phone_title1{
        position: absolute;
        left: 88px;
        right: 0;
        top: 108px;
        width: 180px;
        height: 150px;
        color: white;
        font-size: 25px;
        p{
            font-size: 30px;
            font-weight: 600;
            color: white;
        }
        span{
            font-size: 20px;
            line-height: 60px;
        }
    }

    .section1,.section2{
        margin: 0 32px;

        .text_container{
            .text_item,.text_item1,.text_item2{
                 display: flex;
                 align-items: center;
                 margin-bottom: 120px;
                .point{
                    width:12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #8bc243;
                    margin-right: 22px;
                }

                .text_item_box{
                    flex: 1;
                    height: 35px;
                }
            }

            .text_item1{
                margin-bottom: 192px;
            }

            .text_item2{
                margin-bottom: 232px;
            }
        }
        .img_container{
            display: flex;
            justify-content: center;

            img{
                width: 550px;
                height: auto;
                margin: 0 auto;
            }
        }
    }

    .section2{
        margin-top: 80px;
        .text_container{

            .text_item1{
                margin-bottom: 330px;
            }

            .text_item2{
                margin-bottom: 390px;
            }
        }

        .img_container{
            position: relative;
            width: 686px;
            height: 470px;
            background: rgba(242, 242, 242, 1);

            img{
                width: 592px;
                height: 450px;
                position: absolute;
                top:-74px;
                left:48px;
            }
        }

    }

    .page_container{
        width: 100%;
        padding: 0 32px;
        margin-top: 58px;
        box-sizing: border-box;

        .page_title{
            .page_title_en{
                font-size: 36px;
                line-height: 40px;
                color:#8bc243;
            }

            .page_title_cn{
                font-size: 32px;
                line-height: 64px;
                width: 140px;
                color:#8bc243;
                border-bottom: 2px solid #8bc243;
                margin-bottom: 30px;
            }
        }
        .page_text{
            font-size: 24px;
            line-height: 44px;
            color:#333;
        }

        .page_img{
            width: 686px;
            height: 470px;
            margin-top:134px;
            background-color: #f2f2f2;
            position: relative;

            img{
                width: 592px;
                height: 450px;
                position: absolute;
                left:48px;
                bottom: 94px;
            }
        }
    }
}
</style>