<template>
    <div>
        <div id="page1" class="page1-wrap">
            <img class="page1_about_img" style="background: var(--img-bg)" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/2about/img/1.png" width="100%" alt="">
            <div class="page1_about_title">
                <div class="page1_about_title1"></div>
                <div class="page2_about_title2"></div>
            </div>
            <div class="about_container_title">
                <div class="about_container_title_en">COMPANY  INTRODUCTION</div>
                <div class="about_container_title_cn">企业简介</div>
                <div class="about_container_title_son">
                    <div class="round"></div>
                    <div class="t_1">新疆好物疆至企业管理有限公司成立于2021年，注册资本2000万人民币。</div></div>
                <div class="about_container_title_son">
                    <div class="round"></div>
                    <div class="t_1">好物疆至以“新疆好物传颂者”为企业定位，以“重塑本地好物价值体系，促进本地特色文化传递，领航本地产业结构升级，带动本地人民共同富裕”为企业使命，以“丰富社会主义市场经济体制，共享社会主义经济发展红利”为企业愿景。</div>
                </div>
                <div class="about_container_title_son">
                    <div class="round"></div>
                    <div class="t_1">通过旗下旅游开发、文化传媒、贸易销售、食品加工、酒店运营、文创研发等相关子公司，响应国家优化发展实体经济的政策，以幸福美好生活为导向，充分利用大美新疆的品质在地资源和历史文化传承，为广大消费者提供畅游新疆美景、感受新疆风情、持续消费新疆好物的多元复合场景综合服务。</div>
                </div>
            </div>
            <div class="about_container_img">
                <img class="about_container_img_1" style="background: var(--img-bg)" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/2about/img/%E4%BC%81%E4%B8%9A%E7%AE%80%E4%BB%8B.jpg" width="100%" alt="">
            </div>
        </div>

        <div class="about_container_title_one">
            <div class="about_container_title">
                <div class="about_container_title_en">好物疆至以旅游为切入点，打通一二三产业链，实现高质量发展。好物疆至将通过旅游产业园（野奢酒店、可视化互动体验产销区等）完成流量沉淀和认知输出，通过新消费矩阵（疆内特产文创店、疆外零食瓜果铺、疆外新疆生活馆等）完成产品升级和产品输出，通过终端售卖数据精准判断市场趋势，与本地人民进行可视化种养殖、可视化加工厂的全面合作，实现更合理的产能分配，达到优化产业结构的目标，最终打通一二三产业，创造价值增量，实现整体化高质量发展。</div>
                <div class="about_container_text">好物疆至还将以APP为媒介，实现通过以旅游为切入点建立的全产业链体系的利润共享。在好物疆至APP上，短视频等各类型的线上娱乐只是基础功能，更核心的是我们会将产业链上每个环节的经营单体（酒店、连锁零售门店、种养殖基地、加工厂等）进行运营、财务的全透明线上化，用户可在APP上实现对项目的全透明化投资操作、日常运营监督和收益获取。每个单体项目我们都将设置较低的投资参与额度，以此让更广大的人民群众都可以参与到新疆乃至整个中国的发展红利中来，实现真正的共同富裕。
                </div>
            </div>
            <div class="about_container_img">
                <img class="about_container_img_1" style="background: var(--img-bg)" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/2about/img/5.png" width="100%" alt="">
            </div>
        </div>
        <footer1></footer1>
    </div>
</template>

<script>
export default {
    mounted() {
        this.animate.moveIn(".page1_about", ".page1_about", "bottom");
    }
}
</script>

<style lang="scss" scoped>
.page1-wrap {
    position: relative;
    margin-top: 70px;
    height: 1500px;
    margin-bottom: 130px;
    .page1_about_img{
        width: 100%;
        height: 650px;
    }
    .page1_about_title{
        width: 238px;
        height: 138px;
        position: absolute;
        top:160px;
        left:299px;
        color:white;

        .page1_about_title1{
            font-size: 52px;
            line-height: 73px;
            margin-bottom: 17px;
        }
        .page2_about_title2{
            font-size: 24px;
            line-height: 48px;
            font-weight: lighter;
        }
    }
    .about_container_title{
        width: 585px;
        height: 610px;
        position: absolute;
        left:297px;
        top:857px;
        .about_container_title_en{
            font-size: 44px;
            line-height: 52px;
            color:#8BC243;
            margin-bottom: 14px;
        }
        .about_container_title_cn{
            display: inline-block;
            font-size: 30px;
            height: 56px;
            color:#8BC243;
            margin-bottom: 40px;
            border-bottom: 2px solid #8BC243;
        }
      .about_container_title_son{
        font-size: 16px;
        margin-bottom: 50px;
        display: flex;
        //.round{
        //  width: 15px;
        //  height: 15px;
        //  opacity: 1;
        //  background: rgba(139, 194, 67, 1);
        //  margin-right: 20px;
        //  border-radius: 50px;
        //  margin-top: 4px;
        //}
        .t_1{
          font-size: 16px;
          line-height: 28px;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
    .about_container_img{
        width: 610px;
        height: 404px;
        position: absolute;
        top:938px;
        right:300px;
        .about_container_img_1{
            width: 639px;
            height: 360px;
            position: relative;
            margin-top: 90px;
        }
    }
}

.about_container_title_one{
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  .about_container_title{
    width: 585px;
    height: 610px;
    left:297px;
    top:857px;
    margin-left: 80px;
    .about_container_title_en,.about_container_text{
      font-size: 16px;
      line-height: 28px;
      color: rgba(51, 51, 51, 1);
      position: relative;
      bottom: 75px;
    }
    .about_container_text{
      margin-top: 50px;
    }
  }
  .about_container_img{
    width: 610px;
    height: 399px;
    top:938px;
    right:300px;
    background-color: #f6f6f6;
    .about_container_img_1{
      width: 522px;
      height: 397px;
      position: relative;
      bottom: 75px;
      left:88px;
    }
  }
}
</style>